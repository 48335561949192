import { Box, Button, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const DeleteConfirmationModal = ({
  deleteModalOpen,
  deleteModalClose,
  handleDelete,
}) => {
  return (
    <Modal
      open={deleteModalOpen}
      onClose={deleteModalClose}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
      <Box sx={style}>
        <Typography id="delete-modal-title" variant="h6" component="h2">
          Confirm Deletion
        </Typography>
        <Typography id="delete-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to delete the selected data? This action cannot be undone.
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color='primary'
            onClick={() => {
              handleDelete(); // Call the delete function
              deleteModalClose(); // Close the modal
            }}
          >
            Delete
          </Button>
          <Button variant="outlined" onClick={deleteModalClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  deleteModalClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
