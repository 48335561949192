// src/pages/data/dataW/UploadModal.jsx

import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Matrix from '../Matrix';
import ExportToExcelButton from '@crema/core/AppExportExeclFile';
import LoadingButtons from 'pages/muiComponents/inputs/Buttons/LoadingButtons';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  minHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const confirmationModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const UploadModal = ({
  open,
  handleClose,
  onSubmit,
  dominId,
  allStatusCount,
  selecteCustomer,
  doneStatusCount,
  errorStatusCount,
  domainSelected,
  handleDomainChange,
  affiliateId,
  onDropDownChange,
  onDropDownSearch,
  errorData,
  upLoadingData,
  handleClear,
}) => {
  // State to manage confirmation modal visibility
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // State to store selected customer name
  const [selectedCustomerName, setSelectedCustomerName] = useState('');

  // Handler for client (AI) selection changes
  const handleClientChange = (event, valueSelected) => {
    if (valueSelected) {
      setSelectedCustomerName(valueSelected.label);
    } else {
      setSelectedCustomerName('');
    }
    onDropDownChange(event, valueSelected);
  };

  // Open confirmation modal
  const handleOpenConfirmModal = () => {
    if (!selectedCustomerName) {
      // Optionally, display an error or prevent opening the modal
      alert('Please select a client before sending.');
      return;
    }
    setOpenConfirmModal(true);
  };

  // Close confirmation modal
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  // Confirm sending data
  const handleConfirmSend = () => {
    setOpenConfirmModal(false);
    onSubmit(); // Proceed with sending data
  };

  return (
    <>
      {/* Main Upload Modal */}
      <Modal
        open={open}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        onClose={handleClose}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography id='parent-modal-title' variant='h6'>
              Choose Domain & Client
            </Typography>
            <IconButton onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Domain Selection */}
          <Box m={2}>
            <Autocomplete
              fullWidth
              disablePortal
              id='combo-box-domain'
              options={dominId}
              onChange={handleDomainChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Domain'
                  onChange={onDropDownSearch}
                />
              )}
            />
          </Box>

          {/* Client (AI) Selection */}
          {domainSelected && (
            <>
              <Typography variant='subtitle1' sx={{mt: 2}}>
                Choose Client (AI)...
              </Typography>
              <Box m={2}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id='combo-box-client'
                  options={affiliateId}
                  onChange={handleClientChange} // Use the new handler
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Client'
                      onChange={onDropDownSearch}
                    />
                  )}
                />
              </Box>
            </>
          )}

          {/* Status Matrix and Error Export */}
          {allStatusCount > 0 && (
            <Box m={2}>
              <Matrix
                allStatusCount={allStatusCount}
                doneStatusCount={doneStatusCount}
                errorStatusCount={errorStatusCount}
                title='API Sending'
              />
              {errorStatusCount > 0 && (
                <ExportToExcelButton
                  fileName={`error-${new Date().toISOString()}`}
                  json_data={errorData}
                />
              )}
            </Box>
          )}

          {/* Action Buttons */}
          {!upLoadingData && allStatusCount === 0 && (
            <>
              {selecteCustomer && domainSelected && (
                <Box m={2} sx={{display: 'inline'}}>
                  <Button
                    variant='contained'
                    onClick={handleOpenConfirmModal} // Open confirmation modal
                  >
                    Send
                  </Button>
                </Box>
              )}
              <Box m={2} sx={{display: 'inline'}}>
                <Button variant='outlined' onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </>
          )}
          {!upLoadingData && allStatusCount > 0 && (
            <Box m={2} sx={{display: 'inline'}}>
              <Button
                variant='outlined'
                onClick={() => {
                  handleClose();
                  handleClear();
                }}
              >
                Done
              </Button>
            </Box>
          )}
          {upLoadingData && (
            <Box m={2} sx={{display: 'inline'}}>
              <LoadingButtons loading variant='outlined'>
                Sending...
              </LoadingButtons>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={openConfirmModal}
        aria-labelledby='confirmation-modal-title'
        aria-describedby='confirmation-modal-description'
        onClose={handleCloseConfirmModal}
      >
        <Box sx={confirmationModalStyle}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography id='confirmation-modal-title' variant='h6'>
              Confirm Sending Data
            </Typography>
            <IconButton onClick={handleCloseConfirmModal} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id='confirmation-modal-description' sx={{mt: 2}}>
            Are you sure you want to send the data to{' '}
            <strong>{selectedCustomerName}</strong>?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 4,
              gap: 2,
            }}
          >
            <Button
              onClick={handleCloseConfirmModal}
              color='secondary'
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmSend}
              color='primary'
              variant='contained'
            >
              Yes, Send
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

UploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dominId: PropTypes.array.isRequired,
  allStatusCount: PropTypes.number.isRequired,
  doneStatusCount: PropTypes.number.isRequired,
  errorStatusCount: PropTypes.number.isRequired,
  selecteCustomer: PropTypes.bool.isRequired,
  domainSelected: PropTypes.bool.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  affiliateId: PropTypes.array.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onDropDownSearch: PropTypes.func.isRequired,
  errorData: PropTypes.any.isRequired,
  upLoadingData: PropTypes.bool.isRequired,
  handleClear: PropTypes.func.isRequired,
};

export default UploadModal;
